<template>
  <div>
    <ShopBanner />
    <section class="section">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <h3 class="title is-3 is-bold">
              Warenkorb {{ session_count_display }}
            </h3>
          </div>
          <div class="level-right">
            <div class="buttons is-right" v-if="products.length > 0">
              <router-link class="button is-medium" :to="{ name: 'shop' }">
                Weiter einkaufen
              </router-link>
              <router-link
                class="button is-primary is-medium"
                :disabled="products.length == 0"
                :to="{ name: 'checkout' }"
              >
                Zur Kasse
              </router-link>
            </div>
          </div>
        </div>

        <div class="notification is-danger" v-if="error">
          {{ error }}
        </div>

        <b-loading
          :is-full-page="false"
          v-model="is_loading"
          :can-cancel="false"
        ></b-loading>

        <div v-if="is_loading">
          <hr />
          <table class="table is-fullwidth">
            <tr v-for="row in session_count" :key="row">
              <td>
                <b-skeleton
                  width="100%"
                  height="30px"
                  :animated="true"
                ></b-skeleton>
              </td>
            </tr>
          </table>
        </div>
        <div v-else>
          <hr />
          <div v-if="products.length == 0">
            <h3 class="title is-4">Noch keine Artikel im Warenkorb.</h3>
            <div class="buttons">
              <router-link
                class="button is-medium is-primary is-outlined"
                :to="{ name: 'shop' }"
                >Zur Artikelübersicht</router-link
              >
            </div>
          </div>
          <div v-else>
            <table class="table is-striped is-fullwidth">
              <tr>
                <th>Artikel</th>
                <th width="20px"></th>
                <th width="80px" colspan="1" align="right">Betrag</th>
                <th width="110px">
                  <b-button
                    label="Leeren"
                    title="Gesamten Warenkorb leeren"
                    size="is-small"
                    type="is-danger is-outlined"
                    icon-left="delete-outline"
                    @click="clearAllPositions()"
                  ></b-button>
                </th>
              </tr>
              <tr v-for="(product, index) in products" :key="index">
                <td>
                  {{ display_position(product) }}
                </td>
                <td>CHF</td>
                <td align="right">
                  {{ formatNumberToTwoDigits(sum_position(product)) }}
                </td>
                <td align="right">
                  <div
                    class="buttons is-right"
                    v-if="product.type != 'calculated'"
                  >
                    <b-button
                      title="Artikel in Warenkorb bearbeiten"
                      size="is-small"
                      type="is-primary is-outlined"
                      icon-left="pencil-outline"
                      @click="updatePosition(product)"
                      v-if="!product.product_amount"
                    ></b-button>
                    <b-button
                      title="Artikel aus Warenkorb entfernen"
                      size="is-small"
                      type="is-primary is-outlined"
                      icon-left="close"
                      @click="removePosition(product)"
                    ></b-button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Summe</th>
                <th>CHF</th>
                <th align="right">
                  {{ formatNumberToTwoDigits(sum_all_positions()) }}
                </th>
                <th></th>
              </tr>
            </table>

            <div class="buttons is-right">
              <router-link class="button is-medium" :to="{ name: 'shop' }"
                >Weiter einkaufen</router-link
              >
              <router-link
                class="button is-primary is-medium"
                :to="{ name: 'checkout' }"
                >Zur Kasse</router-link
              >
            </div>
          </div>
        </div>
        <hr />
      </div>
    </section>
  </div>
</template>
<style scoped>
tr,
th {
  font-size: 20px;
}
</style>
<script>
import Vue from "vue";
import ShopBanner from "./ShopBanner.vue";
import { load, deleteCart, update } from "./shopping_cart_helper";
export default {
  metaInfo: {
    title: "Warenkorb Online-Shop",
  },
  data: function () {
    return {
      products: [],
      is_loading: false,
      error: null,
      bus: new Vue(),
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.error = null;
      if (!this.session_id) {
        this.products = [];
        return;
      }
      this.is_loading = true;
      load(this.session_id, false, (resp) => {
        this.is_loading = false;
        if (resp.state) {
          this.products = resp.products;
          this.$cookies.set("scc", this.session_product_count, "7d");
          this.bus.$emit("sccrefresh");
        } else {
          this.error = "Es ist ein Fehler aufgetreten.";
        }
      });
    },
    sum_position(product) {
      let price = product.product_price.product;
      if (price) {
        let count = product.product_count ? product.product_count : 1;
        return count * price;
      }
      return 0;
    },
    sum_all_positions() {
      if (this.products) {
        return this.products.reduce(
          (partialSum, product) =>
            partialSum + Number(this.sum_position(product)),
          0
        );
      }
      return 0;
    },
    formatNumberToTwoDigits(number) {
      return Number(number).toFixed(2);
    },
    updatePosition(product) {
      this.$buefy.dialog.prompt({
        message: `Anzahl von <b>'${product.product_name}'</b> bearbeiten`,
        inputAttrs: {
          type: "number",
          placeholder: "Anzahl",
          value: product.product_count,
          min: 0,
          max: 100,
        },
        confirmText: "Warenkorb aktualisieren",
        cancelText: "Abbrechen",
        trapFocus: true,
        onConfirm: (value) => {
          update(this.session_id, product.product_id, value, (resp) => {
            if (resp.state) {
              this.$buefy.toast.open({
                duration: 3000,
                message: `Warenkorb aktualisiert`,
                type: "is-success",
              });
              this.loadData();
            } else {
              let message = resp.message ? resp.message : "";
              this.$buefy.toast.open({
                duration: 6000,
                message: `Warenkorb konnte nicht aktualisiert werden. ${message}`,
                type: "is-danger",
              });
            }
          });
        },
      });
    },
    removePosition(product) {
      if (!product.product_id) {
        return;
      }
      this.$buefy.dialog.confirm({
        title: "Artikel aus Warenkorb entfernen?",
        message: "Möchten Sie diesen Artikel aus dem Warenkorb entfernen?",
        cancelText: "Abbrechen",
        confirmText: "Artikel entfernen",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () => {
          deleteCart(this.session_id, product.product_id, (resp) => {
            if (!resp.state) {
              alert(
                "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
              );
            } else {
              if (resp.deleted) {
                this.clearCookies();
                this.products = [];
              } else {
                this.loadData();
              }
            }
          });
        },
      });
    },
    clearAllPositions() {
      this.$buefy.dialog.confirm({
        title: "Warenkorb vollständig leeren?",
        message: "Möchten Sie alle Artikel aus dem Warenkorb entfernen?",
        cancelText: "Abbrechen",
        confirmText: "Warenkorb leeren",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () => {
          deleteCart(this.session_id, null, (resp) => {
            if (!resp.state) {
              alert(
                "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
              );
            } else {
              if (resp.deleted) {
                this.clearCookies();
                this.products = [];
              }
            }
          });
        },
      });
    },
    clearCookies() {
      this.$cookies.remove("sct");
      this.$cookies.remove("scc");
      this.bus.$emit("sccrefresh");
    },
    display_position(product) {
      if (product.product_count) {
        return `${product.product_count} x ${product.product_name}`;
      }
      return product.product_name;
    },
  },
  computed: {
    session_id() {
      return this.$cookies.get("sct");
    },
    session_count() {
      let count = this.$cookies.get("scc");
      return count ? Number(count) : 0;
    },
    session_count_display() {
      if (this.session_product_count > 0) {
        return `(${this.session_product_count})`;
      }
      return "";
    },
    session_product_count() {
      if(this.products) {
        return this.products.filter(product => product.type != 'calculated').length
      }
      return 0
    }
  },
  components: {
    ShopBanner,
  },
};
</script>