<template>
  <section class="hero is-medium is-dark has-background">
    <img :src="srcLinkImage" class="is-transparent hero-background" />
    <div class="hero-body">
      <div class="container">
        <h1 class="title is-1 is-size-2-mobile">
          Online-Shop: Dominosteine für Zuhause
        </h1>
        <h2 class="subtitle">
          Tausende bunte Dominosteine für Kettenreaktionen.
        </h2>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  computed: {
    srcLinkImage() {
      return `https://swissdominoeffect.com/img/background/domino_wallpaper_blue.png`;
    },
  },
};
</script>